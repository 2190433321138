import { useCallback, useEffect, useMemo, useState } from "react";
import CommonContext from "./common.context";
import * as affiliateChannelServices from "services/affiliateChannel";

export default function CommonProvider({ children }) {
  const [categories, setCategories] = useState([]);
  const categoriesById = useMemo(() => {
    const obj = {};

    categories.forEach((categ) => {
      obj[categ.id] = categ;
    });

    return obj;
  }, [categories]);

  const getCategories = useCallback(() => {
    affiliateChannelServices
      .getCollectionTag()
      .then(({ status, data }) => {
        if (status === 200 || status === 201) {
          setCategories(data || []);
        }
      })
      .catch((err) => {
        console.error("getCategories error", err);
      });
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CommonContext.Provider
      value={{
        categories,
        categoriesById,
      }}
    >
      {children || null}
    </CommonContext.Provider>
  );
}
