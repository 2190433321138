const SVGComponent = (props) => (
  <svg
    width={12}
    height={13}
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.31 4.725C9.785 2.415 7.77 1.375 6 1.375C6 1.375 6 1.375 5.995 1.375C4.23 1.375 2.21 2.41 1.685 4.72C1.1 7.3 2.68 9.485 4.11 10.86C4.64 11.37 5.32 11.625 6 11.625C6.68 11.625 7.36 11.37 7.885 10.86C9.315 9.485 10.895 7.305 10.31 4.725ZM6 7.23C5.13 7.23 4.425 6.525 4.425 5.655C4.425 4.785 5.13 4.08 6 4.08C6.87 4.08 7.575 4.785 7.575 5.655C7.575 6.525 6.87 7.23 6 7.23Z'
      fill='#546B8C'
    />
  </svg>
);

export default SVGComponent;
