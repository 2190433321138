import * as React from 'react';

const SVGComponent = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.87379 7.45943C8.48327 7.0689 7.85011 7.0689 7.45958 7.45943C7.06906 7.84995 7.06906 8.48312 7.45958 8.87364L12.5858 13.9999L7.45958 19.1261C7.06906 19.5166 7.06906 20.1498 7.45958 20.5403C7.8501 20.9308 8.48327 20.9308 8.87379 20.5403L14 15.4141L19.1262 20.5403C19.5168 20.9308 20.1499 20.9308 20.5405 20.5403C20.931 20.1498 20.931 19.5166 20.5405 19.1261L15.4142 13.9999L20.5405 8.87364C20.931 8.48312 20.931 7.84995 20.5405 7.45943C20.1499 7.0689 19.5168 7.0689 19.1262 7.45943L14 12.5857L8.87379 7.45943Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default SVGComponent;
