import CustomerInformationCollection from "pages/CustomerInfoCollection";
import Search from "pages/Search";
import { lazy } from "react";

const Home = lazy(() => import("pages/Home"));
const MyCart = lazy(() => import("pages/MyCart"));
const Account = lazy(() => import("pages/Account"));
const MyAddress = lazy(() => import("pages/MyAddress"));
const OrderHistory = lazy(() => import("pages/OrderHistory"));
const EditAddress = lazy(() => import("pages/EditAddress"));
const CollectionDetails = lazy(() => import("pages/CollectionDetails"));

const MAIN_ROUTES = {
  HOME: "/",
  SEARCH: "/search",
  ACCOUNT: "/account",
  MY_CART: "/my-cart",
  COLLECTION: "/collection",
};

const ACCOUNT_ROUTES = {
  MY_ADDRESS: `${MAIN_ROUTES.ACCOUNT}/my-address`,
  ORDER_HISTORY: `${MAIN_ROUTES.ACCOUNT}/order-history`,
  EDIT_ADDRESS: `${MAIN_ROUTES.ACCOUNT}/edit-address`,
};

const MY_CART_ROUTES = {
  CUSTOMER_INFO: `${MAIN_ROUTES.MY_CART}/customer-info`,
};

const MAPPING_ROUTES = [
  { Component: Home, path: MAIN_ROUTES.HOME, exact: true },
  { Component: Account, path: MAIN_ROUTES.ACCOUNT, exact: true },
  {
    Component: CollectionDetails,
    path: `${MAIN_ROUTES.COLLECTION}/:collectionId`,
    exact: true,
  },
  { Component: MyCart, path: MAIN_ROUTES.MY_CART, exact: true },
  { Component: MyAddress, path: ACCOUNT_ROUTES.MY_ADDRESS, exact: true },
  { Component: OrderHistory, path: ACCOUNT_ROUTES.ORDER_HISTORY, exact: true },
  { Component: EditAddress, path: ACCOUNT_ROUTES.EDIT_ADDRESS, exact: true },
  {
    Component: CustomerInformationCollection,
    path: MY_CART_ROUTES.CUSTOMER_INFO,
    exact: true,
  },
  {
    Component: Search,
    path: MAIN_ROUTES.SEARCH,
    exact: true,
  },
];

export { MAIN_ROUTES, ACCOUNT_ROUTES, MAPPING_ROUTES };
