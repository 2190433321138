import * as momentTz from "moment-timezone";
import apiClient from "./apiClient";
import axios from "./axiosInstance";

export const getCollectionTag = () => axios.get("/tags");

export const getCollectionTop = () =>
  axios.get("/affiliate-channel/TK0/collections/top");

export const getCollectionInSlider = () =>
  axios.get("/affiliate-channel/TK0/collections/in-sliders");

export const getCollectionOnBanner = () =>
  axios.get("/affiliate-channel/TK0/collections/on-banner");

export const getCollection = (collectionId) =>
  axios.get(`/affiliate-channel/TK0/collections/${collectionId}`);

export const getCollectionByConfig = () =>
  axios.get("/affiliate-channel/TK0/collections/by-config");

export const getMerchantByCollection = (data) =>
  axios.get(`/affiliate-channel/TK0/collections/${data.id}/merchants`);

export const getMerchantByUsingFilter = (data) =>
  axios.post(`/affiliate-channel/TK0/merchants/filter`, {
    ...data,
    timezone: momentTz.tz.guess(),
  });

export const getMerchantByUsingFilterV2 = (data) =>
  apiClient.post(`/v2/wa/affiliate-channel/TK0/merchants/filter`, {
    ...data,
    timezone: momentTz.tz.guess(),
  });

export const searchAddressByKeyword = (keyword) =>
  axios.get(`/address/by-keyword?keyword=${keyword}`);

export const searchAddressByLatLong = (lat, long) =>
  axios.get(`/address/by-lat-long?lat=${lat}&long=${long}`);

export const getAddressDetailById = (placeId) =>
  axios.get(`/address/${placeId}/detail`);
