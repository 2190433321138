import { useCallback, useEffect, useMemo, useState } from "react";
import {} from "@cabineat/utilities/";
import CachesContext from "./caches.context";
import cookie from "utils/helpers/cookie";
import * as nhsService from "../../services/nhs";
import * as affiliateChannelService from "../../services/affiliateChannel";

const getCustomerInfoFromCache = () => {
  try {
    return JSON.parse(cookie.getCookie("cus-info")) || {};
  } catch (ex) {
    return {};
  }
};

const saveCustomerInfoToCache = (info) => {
  cookie.updateCookie("cus-info", JSON.stringify(info || {}));
};

const getOrdersFromCache = () => {
  try {
    return JSON.parse(cookie.getCookie("orders")) || [];
  } catch (ex) {
    return [];
  }
};

const saveOrdersToCache = (orders) => {
  cookie.updateCookie("orders", JSON.stringify(orders));
};

export default function CachesProvider({ children }) {
  const [mounted, setMounted] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    phone: "",
    address: null,
    ...getCustomerInfoFromCache(),
  });
  const [orderVersion, setOrderVersion] = useState(0);
  const [orders, setOrders] = useState(getOrdersFromCache());

  const processingOrderQuantity = useMemo(() => {
    return orders.filter((order) => order?.orderStatus < 6).length;
  }, [orders]);
  const orderQuantity = useMemo(() => {
    return orders.length;
  }, [orders]);

  useEffect(() => {
    mounted && customerInfo && saveCustomerInfoToCache(customerInfo);
  }, [customerInfo]);

  useEffect(() => {
    mounted && orders && saveOrdersToCache(orders);
  }, [orders]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getAddressDetail = useCallback((placeId) => {
    affiliateChannelService
      .getAddressDetailById(placeId)
      .then(({ status, data }) => {
        if (status === 200 && data) {
          setCustomerInfo((previous) => ({
            ...previous,
            address: {
              ...(previous?.address || {}),
              lat: data?.lat,
              long: data?.long,
            },
          }));
        }
      });
  }, []);

  const getAddressByLatLong = useCallback(
    (lat, long) => {
      if (customerInfo?.address?.placeId || !lat || !long) {
        return;
      }

      affiliateChannelService
        .searchAddressByLatLong(lat, long)
        .then(({ status, data }) => {
          if (status === 200) {
            setCustomerInfo((previous) => ({
              ...previous,
              address: {
                ...(previous?.address || {}),
                ...(data || {}),
              },
            }));
          }
        });
    },
    [customerInfo]
  );

  const changeCustomerInfo = useCallback((info) => {
    setCustomerInfo((previous) => {
      return {
        ...previous,
        ...info,
        address:
          info?.address?.placeId &&
          info?.address?.placeId !== previous?.address?.placeId
            ? {
                ...(info?.address || {}),
                lat: null,
                long: null,
              }
            : previous?.address,
      };
      // if (
      //   info?.address &&
      //   info?.address?.placeId &&
      //   (info?.address?.placeId !== previous?.address?.placeId ||
      //     !previous?.address?.lat ||
      //     !previous?.address?.long)
      // ) {
      //   getAddressDetail(info?.address?.placeId);
      // }

      // return {
      //   ...(previous || {}),
      //   ...(info || {}),
      // };
    });
  }, []);

  const addOrdersToList = useCallback((newOrders = []) => {
    setOrders((previous) => [...newOrders, ...previous]);
  }, []);

  const changeOrderInList = useCallback((orderId, orderData) => {
    setOrders((previous) =>
      previous.map((order) =>
        order?.id === orderId
          ? {
              ...order,
              ...(orderData || {}),
            }
          : order
      )
    );
  }, []);

  const changeOrders = useCallback((newOrders = []) => {
    setOrders(newOrders);
    setOrderVersion((previous) => previous + 1);
  }, []);

  useEffect(() => {
    if (
      customerInfo?.address?.placeId &&
      (!customerInfo?.address?.lat || !customerInfo?.address?.long)
    ) {
      getAddressDetail(customerInfo?.address?.placeId);
    }
  }, [customerInfo]);

  useEffect(() => {
    orders.forEach((order, index) => {
      order?.orderStatus < 6 &&
        nhsService
          .getOrderStatus(order.id, order.nhsId)
          .then(({ status, data }) => {
            if (status === 200 || status === 201) {
              setOrders((previous) =>
                previous.map((item) =>
                  item.id === order.id
                    ? {
                        ...item,
                        orderStatus: data?.orderStatus,
                      }
                    : item
                )
              );
            }
          })
          .catch((err) => {
            // alert(JSON.stringify(err));
          });
    });
  }, [orderVersion]);

  return (
    <CachesContext.Provider
      value={{
        customerInfo,
        orders,
        orderQuantity,
        processingOrderQuantity,

        addOrdersToList,
        changeOrders,
        changeOrderInList,
        changeCustomerInfo,
        getAddressByLatLong,
      }}
    >
      {children || null}
    </CachesContext.Provider>
  );
}
