import { useCallback, useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ArrowDown from "components/svg/ArrowDown";
import styles from "./styles.module.scss";
import CategorySelectionPopup from "./CategorySelectionPopup";
import * as affiliateChannelServices from "services/affiliateChannel";
import useCommon from "providers/common/common.hook";

export default function FilterMerchants({
  initialFilterData = {},
  onChangeFilterData,
}) {
  const [mounted, setMounted] = useState(false);
  const [categorySelectionVisible, setCategorySelectionVisible] =
    useState(false);
  const [filterData, setFilterData] = useState({
    tagIds: [],
    isOpening: false,
    ...(initialFilterData || {}),
  });

  const changeFilterData = useCallback((data = {}) => {
    setFilterData((previous) => ({
      ...previous,
      ...(data || {}),
    }));
  }, []);

  const showCategorySelection = useCallback(() => {
    setCategorySelectionVisible(true);
  }, []);

  const toggleFilterIsOpening = useCallback(() => {
    setFilterData((previous) => ({
      ...(previous || {}),
      isOpening: !previous?.isOpening,
    }));
  }, []);

  useEffect(() => {
    mounted && onChangeFilterData && onChangeFilterData(filterData);
  }, [filterData]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <ScrollContainer horizontal>
      <div className={styles.horizontalList}>
        {/* <div className={styles.horizontalListItem}>
                  <div
                    className={`${styles.tagItem} ${
                      merchantFilterState.nearby ? styles.tagItemActive : ""
                    }`}
                    onClick={onChooseNearBy}
                  >
                    Gần đây
                  </div>
                </div> */}
        <div className={styles.horizontalListItem}>
          <div
            className={`${styles.tagItem} ${
              Array.isArray(filterData.tagIds) && filterData.tagIds.length > 0
                ? styles.tagItemActive
                : ""
            }`}
            onClick={showCategorySelection}
          >
            <span>
              Danh mục
              {Array.isArray(filterData.tagIds) && filterData.tagIds.length > 0
                ? ` (${filterData.tagIds.length})`
                : ""}
            </span>
            <ArrowDown fill="#1A1A1A" />
          </div>
        </div>
        <div className={styles.horizontalListItem}>
          <div
            className={`${styles.tagItem} ${
              filterData.isOpening ? styles.tagItemActive : ""
            }`}
            onClick={toggleFilterIsOpening}
          >
            Đang mở cửa
          </div>
        </div>
      </div>
      <CategorySelectionPopup
        show={categorySelectionVisible}
        categoryIds={filterData.tagIds || []}
        onClose={() => setCategorySelectionVisible(false)}
        onSubmitFilter={(selectedCategories) => {
          changeFilterData({
            tagIds: selectedCategories.map((categ) => categ.id),
          });
          setCategorySelectionVisible(false);
        }}
      />
    </ScrollContainer>
  );
}

FilterMerchants.propTypes = {};
