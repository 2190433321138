import styles from "./styles.module.scss";

import { useHistory } from "react-router-dom";

import ArrowLightLeft from "components/svg/ArrowLightLeft";

const AppBar = ({ title, customTitle }) => {
  const history = useHistory();
  return (
    <div className={styles.root}>
      <div
        onClick={() => {
          if (history.length > 1) {
            history.goBack();
          } else {
            history.push("/");
          }
        }}
        className={styles.arrow}
      >
        <ArrowLightLeft />
      </div>
      <div className={styles.title}>
        {customTitle ? customTitle : <label>{title}</label>}
      </div>
    </div>
  );
};

export default AppBar;
