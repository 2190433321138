import styles from "./styles.module.scss";

const Checkbox = (props) => {
  const { checked, onChange, title } = props;
  return (
    <label className={styles.root}>
      {title && <span className={styles.title}>{title}</span>}
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange && onChange(e, e.target.value)}
      />
      <span className={styles.checkmark} />
    </label>
  );
};

export default Checkbox;
