import styles from "./styles.module.scss";
import AppBar from "components/layout/AppBar";
import { useMemo, useState } from "react";
import useCaches from "providers/caches/caches.hook";
import useGetMetchants from "hooks/get-merchants.hook";
import useCommon from "providers/common/common.hook";
import Skeleton from "react-loading-skeleton";
import ShopCard from "components/module/ShopCard";
import FilterMerchants from "components/module/FilterMerchants";

const Search = () => {
  const { customerInfo } = useCaches();
  const { categoriesById } = useCommon();
  const [keyword, setKeyword] = useState("");
  const [filterData, setFilterData] = useState({
    tagIds: [],
    isOpening: true,
  });
  const finalFilterData = useMemo(() => {
    return {
      keyword,
      ...filterData,
      ...(customerInfo?.address?.lat && customerInfo?.address?.long
        ? {
            currentLocation: {
              lat: customerInfo?.address?.lat,
              long: customerInfo?.address?.long,
            },
          }
        : {}),
    };
  }, [
    filterData,
    customerInfo?.address?.lat,
    customerInfo?.address?.long,
    keyword,
  ]);

  const { gettingMerchants, merchants } = useGetMetchants({
    filterData: finalFilterData,
    disabled: !keyword.trim(),
    reset: !keyword,
  });

  return (
    <>
      <AppBar
        customTitle={
          <div className={styles.inputWrapper}>
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0267 13.848C16.053 12.565 16.6667 10.9375 16.6667 9.16663C16.6667 5.02449 13.3089 1.66663 9.16675 1.66663C5.02461 1.66663 1.66675 5.02449 1.66675 9.16663C1.66675 13.3088 5.02461 16.6666 9.16675 16.6666C10.9376 16.6666 12.5651 16.0529 13.8482 15.0265L16.0775 17.2559C16.4029 17.5813 16.9306 17.5813 17.256 17.2559C17.5814 16.9304 17.5814 16.4028 17.256 16.0774L15.0267 13.848ZM15.0001 9.16663C15.0001 12.3883 12.3884 15 9.16675 15C5.94509 15 3.33341 12.3883 3.33341 9.16663C3.33341 5.94496 5.94509 3.33329 9.16675 3.33329C12.3884 3.33329 15.0001 5.94496 15.0001 9.16663Z"
                fill="#F5544A"
              />
            </svg>

            <input
              className={styles.input}
              placeholder="Tìm nhà hàng, món"
              autoFocus
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        }
      />
      <div
        style={{
          width: "100%",
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 10,
          borderBottom: "1px solid #f1f1f1",
        }}
      >
        <FilterMerchants
          initialFilterData={filterData}
          onChangeFilterData={(newFilterData) => setFilterData(newFilterData)}
        />
      </div>
      <main className={styles.content} style={{ padding: "16px" }}>
        {gettingMerchants
          ? Array.from({ length: 5 }, (_, i) => i).map((_) => (
              <div key={_} style={{ display: "flex", padding: "8px 0" }}>
                <Skeleton width={101} height={101} />
                <div style={{ marginLeft: 16 }}>
                  <Skeleton width={100} style={{ marginBottom: 8 }} />
                  <Skeleton width={160} count={3} />
                </div>
              </div>
            ))
          : merchants.map((merchant) => (
              <div
                key={merchant?.pickupPlace?.id}
                className={styles.shopMoreDetail}
              >
                <ShopCard
                  merchant={merchant}
                  variant="full"
                  name={`${merchant.name || "--"} - ${
                    merchant?.pickupPlace?.name || "--"
                  }`}
                  coverUrl={merchant.extraData?.nhsData.nhs.coverUrl || null}
                  directUrl={merchant?.pickupPlace?.followingNhsLink}
                  coupons={merchant.extraData?.ceData?.coupons || []}
                  categories={merchant.tags.map(
                    (tag) => categoriesById[tag.id] || null
                  )}
                />
              </div>
            ))}
      </main>
    </>
  );
};

export default Search;
