import axios from 'axios';
import { ROOT_API } from 'constance';

const axiosInstance = axios.create({
  baseURL: ROOT_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default axiosInstance;
