import { useEffect, useState } from "react";
import * as affiliateChannelServices from "services/affiliateChannel";

export default function useGetMetchants({ filterData = {}, disabled, reset }) {
  const [filterPage, setFilterPage] = useState({
    page: 1,
    pageSize: 100,
  });
  const [gettingMerchantsCount, setGettingMerchantsCount] = useState(0);
  // const [gettingMerchants, setGettingMerchants] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [timeOut, setTimeOut] = useState(null);
  const [loadIndex, setLoadIndex] = useState(0);

  useEffect(() => {
    if (disabled) {
      return;
    }

    if (timeOut) {
      clearTimeout(timeOut);
    }

    setTimeOut(
      setTimeout(() => {
        const currentLoadIndex = loadIndex + 1;
        setLoadIndex((previous) => previous + 1);
        setGettingMerchantsCount((previous) => previous + 1);
        affiliateChannelServices
          .getMerchantByUsingFilterV2({
            ...filterData,
            ...filterPage,
          })
          .then(({ status, data }) => {
            if (status === 200 || status === 201) {
              setLoadIndex((previous) => {
                if (previous === currentLoadIndex) {
                  setMerchants(data?.data || []);
                }

                return previous;
              });
            }

            setGettingMerchantsCount((previous) => Math.max(0, previous - 1));
          })
          .catch((err) => {
            setGettingMerchantsCount((previous) => Math.max(0, previous - 1));
          });

        setTimeOut(null);
      }, 500)
    );

    return () => {
      clearTimeout(timeOut);
      setTimeOut(null);
    };
  }, [filterData, filterPage, disabled]);

  useEffect(() => {
    if (reset) {
      timeOut && clearTimeout(timeOut);
      setTimeOut(null);
      setMerchants([]);
    }
  }, [reset]);

  return {
    merchants,
    gettingMerchants: gettingMerchantsCount > 0,
  };
}
