import { useEffect } from 'react';
import NProgress from 'nprogress';

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{
          width: 100,
          height: 100,
        }}
        src="/img/loading.gif"
        alt="loading"
      />
    </div>
  );
};

export default LoadingScreen;
