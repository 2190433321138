/* eslint-disable no-undef */
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ThemContext from "./them.context";
import useCaches from "providers/caches/caches.hook";
import { MAIN_ROUTES } from "../../routes";

export default function ThemProvider({ children }) {
  const {
    customerInfo,
    orders,

    addOrdersToList,
    changeOrders,
    changeCustomerInfo,
    getAddressByLatLong,
  } = useCaches();
  const history = useHistory();
  const [mounted, setMounted] = useState(false);
  const [query, setQuery] = useState({});

  const openOrderDetail = useCallback((orderDetailLink) => {
    if (typeof my !== "undefined") {
      my.postMessage({
        action: "OPEN_ORDER_DETAIL",
        data: {
          orderDetailLink,
        },
      });
    }
  }, []);

  const openNhs = useCallback(
    (directUrl) => {
      if (typeof my?.postMessage !== "undefined") {
        const nhsLink =
          directUrl +
          `&ptnr_cinfo_name=${customerInfo?.name || ""}` +
          `&ptnr_cinfo_phone=${encodeURIComponent(customerInfo?.phone || "")}` +
          `&ptnr_cinfo_addr=${
            customerInfo.address?.placeId
              ? encodeURIComponent(JSON.stringify(customerInfo.address))
              : ""
          }`;

        my.postMessage({
          action: "OPEN_NHS",
          data: {
            customerInfo,
            nhsLink,
          },
        });
      }
    },
    [customerInfo]
  );

  // Send changes of customer info to Them
  useEffect(() => {
    if (
      mounted &&
      customerInfo?.name &&
      customerInfo?.phone &&
      customerInfo?.address?.placeId &&
      customerInfo?.address?.detail &&
      typeof my !== "undefined" &&
      typeof my?.postMessage === "function"
    ) {
      my.postMessage({
        msg: "NEW_CUS_INFO",
        data: {
          customerInfo: {
            name: customerInfo.name,
            phone: customerInfo.phone,
            address: {
              placeId: customerInfo?.address?.placeId,
              detail: customerInfo?.address?.detail,
              lat: customerInfo?.address?.lat,
              long: customerInfo?.address?.long,
            },
          },
        },
      });
    }
  }, [
    customerInfo?.name,
    customerInfo?.phone,
    customerInfo?.address?.placeId,
    customerInfo?.address?.detail,
    customerInfo?.address?.lat,
    customerInfo?.address?.long,
  ]);

  useEffect(() => {
    if (
      mounted &&
      typeof my !== "undefined" &&
      typeof my?.postMessage === "function"
    ) {
      my.postMessage({
        msg: "ORDERS",
        data: {
          orders,
        },
      });
    }
  }, [orders]);

  // Catch event from Them
  useEffect(() => {
    const handleMessageFromExternal = (e) => {
      if (e.data?.msg === "NEW_CUS_INFO") {
        const { customerInfo = {} } = e.data?.data || {};
        changeCustomerInfo && changeCustomerInfo({ ...customerInfo });
      }

      if (e.data?.msg === "LOCATION") {
        const { location = {} } = e.data?.data || {};
        getAddressByLatLong &&
          getAddressByLatLong(location?.lat, location?.long);
      }

      if (e.data?.msg === "ORDERS") {
        changeOrders && changeOrders([...(e.data?.data?.orders || [])]);
      }

      if (e?.data?.msg === "NEW_ORDERS") {
        addOrdersToList && addOrdersToList([...(e?.data?.data?.orders || [])]);
      }

      if (e?.data?.msg === "QUERY") {
        setQuery(e?.data?.data?.query || {});
      }

      if (e?.data?.action === "OPEN_COLLECTION") {
        e?.data?.data?.collectionId &&
          history.push(`${MAIN_ROUTES.COLLECTION}/${e.data.data.collectionId}`);
      }
    };

    window.addEventListener("message", handleMessageFromExternal);
    window.onload = function () {
      // Send signal READY to Them
      setTimeout(() => {
        if (typeof my !== "undefined") {
          my.postMessage({
            msg: "READY",
            data: null,
          });
        }
      }, 100);
    };

    return () => {
      window.removeEventListener("message", handleMessageFromExternal);
    };
  }, [
    changeCustomerInfo,
    addOrdersToList,
    changeOrders,
    getAddressByLatLong,
    history,
  ]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <ThemContext.Provider
      value={{
        openOrderDetail,
        openNhs,
      }}
    >
      {children || null}
    </ThemContext.Provider>
  );
}
