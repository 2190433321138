import styles from "./styles.module.scss";
import AppBar from "components/layout/AppBar";
import { useEffect, useState } from "react";
import * as affiliateChannelApi from "../../services/affiliateChannel";
import useCaches from "providers/caches/caches.hook";
import { useHistory } from "react-router-dom";
import useAddress from "../../providers/address/address.hook";

const CustomerInformationCollection = () => {
  const history = useHistory();
  const { changeCustomerInfo } = useCaches();
  const { cachedAddresses, saveAddressToCache } = useAddress();
  const [keyword, setKeyword] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [timeOut, setTimeOut] = useState(null);

  useEffect(() => {
    if (timeOut) {
      clearTimeout(timeOut);
    }

    if (keyword) {
      setTimeOut(
        setTimeout(() => {
          affiliateChannelApi
            .searchAddressByKeyword(keyword)
            .then(({ status, data }) => {
              if (status === 200) {
                setAddresses(data || []);
              }
            });

          setTimeOut(null);
        }, 500)
      );
    } else {
      setTimeOut(null);
    }
  }, [keyword]);

  return (
    <>
      <AppBar
        customTitle={
          <div className={styles.inputWrapper}>
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.91675 8.59809C2.91675 4.76487 6.11998 1.66663 9.99462 1.66663C13.8802 1.66663 17.0834 4.76487 17.0834 8.59809C17.0834 10.5297 16.3809 12.323 15.2247 13.8429C13.9491 15.5196 12.3769 16.9803 10.6072 18.127C10.2022 18.392 9.83664 18.412 9.39212 18.127C7.61236 16.9803 6.04015 15.5196 4.7755 13.8429C3.6184 12.323 2.91675 10.5297 2.91675 8.59809ZM7.66192 8.81391C7.66192 10.098 8.70978 11.108 9.9946 11.108C11.2803 11.108 12.3382 10.098 12.3382 8.81391C12.3382 7.53978 11.2803 6.48065 9.9946 6.48065C8.70978 6.48065 7.66192 7.53978 7.66192 8.81391Z"
                fill="#F5544A"
              />
            </svg>
            <input
              className={styles.input}
              placeholder=" Nhập địa chỉ nhận hàng"
              autoFocus
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
          </div>
        }
      />
      <main className={styles.content}>
        {(addresses.length > 0 ? addresses : cachedAddresses).map((address) => (
          <div
            className={styles.address}
            key={address?.placeId}
            onClick={(e) => {
              saveAddressToCache &&
                saveAddressToCache(address?.placeId, address);
              changeCustomerInfo &&
                changeCustomerInfo({
                  address,
                });
              history.length > 1 ? history.goBack() : history.push("/");
            }}
          >
            <svg
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.91675 8.59809C2.91675 4.76487 6.11998 1.66663 9.99462 1.66663C13.8802 1.66663 17.0834 4.76487 17.0834 8.59809C17.0834 10.5297 16.3809 12.323 15.2247 13.8429C13.9491 15.5196 12.3769 16.9803 10.6072 18.127C10.2022 18.392 9.83664 18.412 9.39212 18.127C7.61236 16.9803 6.04015 15.5196 4.7755 13.8429C3.6184 12.323 2.91675 10.5297 2.91675 8.59809ZM7.66192 8.81391C7.66192 10.098 8.70978 11.108 9.9946 11.108C11.2803 11.108 12.3382 10.098 12.3382 8.81391C12.3382 7.53978 11.2803 6.48065 9.9946 6.48065C8.70978 6.48065 7.66192 7.53978 7.66192 8.81391Z"
                fill="#F5544A"
              />
            </svg>
            <span>{address?.detail}</span>
          </div>
        ))}
      </main>
    </>
  );
};

export default CustomerInformationCollection;
