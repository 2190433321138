import { Suspense } from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import LoadingScreen from "components/module/LoadingScreen";
import ScrollToTop from "components/module/ScrollToTop";

import { MAPPING_ROUTES } from "./routes";

import styles from "./App.module.scss";
import ThemProvider from "providers/them/them.provider";
import CachesProvider from "providers/caches/caches.provider";
import AddressProvider from "./providers/address/address.provider";
import CommonProvider from "providers/common/common.provider";

function App() {
  return (
    <Router>
      <CachesProvider>
        <ThemProvider>
          <AddressProvider>
            <CommonProvider>
              <Suspense fallback={<LoadingScreen />}>
                <div className={styles.root}>
                  <div className={styles.content}>
                    <ScrollToTop />
                    <Switch>
                      {MAPPING_ROUTES.map((route, i) => {
                        const { Component, path, exact } = route;
                        return (
                          <Route key={i} path={path} exact={exact}>
                            <Component />
                          </Route>
                        );
                      })}
                    </Switch>
                  </div>
                </div>
              </Suspense>
            </CommonProvider>
          </AddressProvider>
        </ThemProvider>
      </CachesProvider>
    </Router>
  );
}

export default App;
