/* eslint-disable no-undef */
import { memo, useCallback, useMemo } from "react";
import styles from "./styles.module.scss";

// import StarIcon from 'components/svg/Star';
import Basket from "components/svg/Basket";
import LocationIcon from "components/svg/Location";
import { limitOrderCount } from "utils/helpers/convert";
import { wrapMediaLink } from "utils/helpers/wrap-media-link";
import useThem from "providers/them/them.hook";
import { formatDistance } from "utils/helpers/format-distance";
import useCaches from "providers/caches/caches.hook";

// const coupon = Array.from({ length: 4 }, (_, i) => `Coupon${i}`);

const url =
  "https://images.unsplash.com/photo-1634711370733-0f99254d42a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80";

const ShopCard = (props) => {
  const {
    variant = "simple",
    coverUrl,
    coupons = [],
    categories = [],
    directUrl = "",
    merchant,
  } = props;
  const { openNhs } = useThem();
  const outOfWorkingTime = useMemo(() => {
    return (
      !merchant?.pickupPlace?.statuses?.isAbleToReceiveOrder ||
      !merchant?.pickupPlace?.statuses?.isOpening
    );
  }, [merchant]);

  const WrapperComponent = useCallback(
    ({ children, className }) => {
      if (directUrl) {
        return (
          <a
            className={className}
            style={{ textDecoration: "none" }}
            href={directUrl}
            onClick={(e) => {
              if (typeof my !== "undefined") {
                e.preventDefault();
                openNhs && openNhs(directUrl);
              }
            }}
          >
            {children}
          </a>
        );
      } else {
        return <div className={className}>{children}</div>;
      }
    },
    [directUrl, openNhs]
  );

  if (variant === "full") {
    return (
      <WrapperComponent className={styles.full}>
        <div
          className={styles.image}
          // style={{ backgroundImage: `url(${wrapMediaLink(coverUrl || url)})` }}
        >
          <img
            src={wrapMediaLink(coverUrl || url)}
            alt="nhs-cover"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              opacity: outOfWorkingTime ? 0.5 : 1,
              objectFit: "cover",
            }}
          />
          {outOfWorkingTime && (
            <div
              style={{
                width: "fit-content",
                padding: "2px 4px",
                background: "#EA3858",
                borderRadius: "2px",
                color: "#fff",
                fontSize: "0.475rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                position: "absolute",
                bottom: "9px",
                left: "0px",
                zIndex: 1,
              }}
            >
              NGOÀI GIỜ HOẠT ĐỘNG
            </div>
          )}
        </div>
        <div
          className={styles.infoContainer}
          style={{
            opacity: outOfWorkingTime ? 0.5 : 1,
          }}
        >
          <label>
            {merchant?.name} - {merchant?.pickupPlace?.name}
          </label>
          <span className={styles.shopType}>
            {categories.map((_) => _?.name || "").join(" - ")}
          </span>
          <div className={styles.subInfo}>
            {/* <div className={styles.rating}>
              <Basket width={12} height={12} fill="#546B8C" />
              
              <span className={styles.ratingCount}>{limitOrderCount(232)}</span>
            </div> */}
            {/* <div className={styles.dot} /> */}
            <div className={styles.distance}>
              <LocationIcon />
              <span className={styles.longDistance}>
                {formatDistance(merchant?.pickupPlace?.estimatedDistance)} km
              </span>
            </div>
          </div>
          <div className={styles.couponContainer}>
            {coupons.map((item) => (
              <div key={item.id} className={styles.coupon}>
                {item.couponCode}
              </div>
            ))}
          </div>
        </div>
      </WrapperComponent>
    );
  }

  return (
    <WrapperComponent className={styles.simple}>
      <div
        className={styles.image}
        // style={{ backgroundImage: `url(${wrapMediaLink(coverUrl || url)})` }}
      >
        <img
          src={wrapMediaLink(coverUrl || url)}
          alt="nhs-cover"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            opacity: outOfWorkingTime ? 0.5 : 1,
            objectFit: "cover",
          }}
        />
        {outOfWorkingTime && (
          <div
            style={{
              width: "fit-content",
              padding: "2px 4px",
              background: "#EA3858",
              borderRadius: "2px",
              color: "#fff",
              fontSize: "0.675rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              position: "absolute",
              bottom: "9px",
              left: "9px",
              zIndex: 1,
            }}
          >
            NGOÀI GIỜ HOẠT ĐỘNG
          </div>
        )}
      </div>
      <div
        className={styles.infoContainer}
        style={{
          opacity: outOfWorkingTime ? 0.5 : 1,
        }}
      >
        <label>
          {merchant?.name}-{merchant?.pickupPlace?.name}
        </label>
        <div className={styles.subInfo}>
          {/* <div className={styles.rating}>
            <Basket width={12} height={12} fill="#546B8C" />
            
            <span className={styles.ratingCount}>{limitOrderCount(232)}</span>
          </div> */}
          {/* <div className={styles.dot} /> */}
          <div className={styles.distance}>
            <LocationIcon />
            <span className={styles.longDistance}>
              {formatDistance(merchant?.pickupPlace?.estimatedDistance)} km
            </span>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

export default memo(ShopCard);
