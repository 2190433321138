import { useState, useEffect, useMemo, useCallback } from "react";
import Portal from "components/base/Portal";
import styles from "./styles.module.scss";

import Checkbox from "components/base/Checkbox";

import CloseIcon from "components/svg/Close";
import useCommon from "providers/common/common.hook";

const CategorySelectionPopup = ({
  onClose,
  categoryIds = [],
  show,
  onSubmitFilter,
}) => {
  const { categories } = useCommon();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const selectedCategoriesById = useMemo(() => {
    const obj = {};

    selectedCategories.forEach((category) => {
      obj[category.id] = category;
    });

    return obj;
  }, [selectedCategories]);
  const categoriesById = useMemo(() => {
    const obj = {};

    categories.forEach((category) => {
      obj[category.id] = category;
    });

    return obj;
  }, [categories]);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmitFilter && onSubmitFilter(selectedCategories);
  }, [onSubmitFilter, selectedCategories]);

  const toggleItem = useCallback(
    (value) => {
      if (value === "all") {
        setSelectedCategories((previous) =>
          previous.length === categories.length ? [] : [...categories]
        );
      } else {
        setSelectedCategories((previous) => {
          const index = previous.findIndex((categ) => categ.id === value);

          if (index > -1) {
            return previous.filter((categ) => categ.id !== value);
          }

          return [...previous, categoriesById[value]];
        });
      }
    },
    [categoriesById, categories]
  );

  useEffect(() => {
    setSelectedCategories(categoryIds.map((id) => categoriesById[id]));
  }, [categoryIds, categoriesById]);

  return (
    <Portal show={show} onClose={onClose}>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <header>
            <span onClick={handleClose} className={styles.closeBtn}>
              <CloseIcon />
            </span>
            <div className={styles.title}>Danh mục</div>
          </header>
          <main>
            <div className={styles.listOptions}>
              <div onClick={() => toggleItem("all")} className={styles.item}>
                <span className={styles.title}>Tất cả</span>
                <Checkbox
                  checked={categories.length === selectedCategories.length}
                  onChange={() => toggleItem("all")}
                />
              </div>
              <hr />
              {categories.map((categ) => (
                <div
                  key={categ.id}
                  onClick={() => toggleItem(categ.id)}
                  className={styles.item}
                >
                  <span className={styles.title}>{categ?.name}</span>
                  <Checkbox
                    checked={selectedCategoriesById[categ.id] ? true : false}
                    onChange={() => toggleItem(categ.id)}
                  />
                </div>
              ))}
            </div>
            <div className={styles.btnWrapper}>
              <button onClick={handleSubmit}>Áp dụng</button>
            </div>
          </main>
        </div>
      </div>
    </Portal>
  );
};

export default CategorySelectionPopup;
