import { omit } from "lodash";
import { useEffect, useMemo, useState } from "react";
import AddressContext from "./address.context";

export default function AddressProvider({ children }) {
  const addressKey = useMemo(() => "addrs", []);
  const [cachedAddresses, setCachedAddresses] = useState([]);

  const getAddressesObjectFromCache = () => {
    try {
      const addressesString = localStorage.getItem(addressKey) || "{}";
      return JSON.parse(addressesString);
    } catch (ex) {
      return {};
    }
  };

  const getAddressesFromCache = () => {
    const addresses = [];
    const addressesObj = getAddressesObjectFromCache();

    for (const placeId in addressesObj) {
      addresses.push(addressesObj[placeId]);
    }

    return addresses;
  };

  const saveAddressToCache = (placeId, address) => {
    const addressesObj = getAddressesObjectFromCache();
    addressesObj[placeId] = address;
    localStorage.setItem(addressKey, JSON.stringify(addressesObj));
    loadCachedAddresses();
  };

  const removeAddressFromCache = (placeId) => {
    const addressesObj = getAddressesObjectFromCache();
    localStorage.setItem(
      addressKey,
      JSON.stringify(omit(addressesObj, [placeId]))
    );
    loadCachedAddresses();
  };

  const removeAllAddressFromCache = () => {
    localStorage.setItem(addressKey, JSON.stringify({}));
  };

  const loadCachedAddresses = () => {
    // if (CACHE_ADDRESS_ID !== localStorageService.getItem("nhs-caddr-id")) {
    //   localStorageService.setItem("nhs-caddr-id", CACHE_ADDRESS_ID);
    //   setCachedAddresses([]);
    //   removeAllAddressFromCache();
    // } else {
    // }
    setCachedAddresses(getAddressesFromCache());
  };

  useEffect(() => {
    loadCachedAddresses();
  }, []);

  return (
    <AddressContext.Provider
      value={{
        cachedAddresses,
        getAddressesFromCache,
        removeAddressFromCache,
        saveAddressToCache,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}
