import * as React from 'react';

const SVGComponent = (props) => (
  <svg width={28} height={28} viewBox='0 0 28 28' fill='none' {...props}>
    <path
      d='M18.0834 22.1667L9.91671 14L18.0834 5.83337'
      stroke='#1A1A1A'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SVGComponent;
