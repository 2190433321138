import axios from "axios";
import { NHS_API } from "constance";

const axiosInstance = axios.create({
  baseURL: NHS_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const getOrderStatus = (orderId, nhsId) =>
  axiosInstance.get(`/v1/nhahangso/booking/order/by-id/${orderId}/status`, {
    headers: {
      "nhahangso-id": nhsId,
    },
  });
