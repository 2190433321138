import * as React from 'react';

const SVGComponent = (props) => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 28}
      height={height || 28}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5249 3.15831L6.51656 8.16668H3.67844C2.96847 8.16668 2.4231 8.79549 2.5235 9.49833L4.23731 21.495C4.48363 23.2193 5.96036 24.5 7.70213 24.5H20.2977C22.0395 24.5 23.5162 23.2193 23.7626 21.495L25.4764 9.49833C25.5768 8.7955 25.0314 8.16668 24.3214 8.16668H21.4831L16.4747 3.1583C15.1078 1.79147 12.8918 1.79147 11.5249 3.15831ZM14.8248 4.80822C14.3692 4.35261 13.6305 4.35261 13.1749 4.80822L9.8164 8.16668L18.1832 8.16668L14.8248 4.80822ZM10.4999 14C10.4999 13.3557 9.97758 12.8333 9.33325 12.8333C8.68892 12.8333 8.16659 13.3557 8.16659 14V18.6667C8.16659 19.311 8.68892 19.8333 9.33325 19.8333C9.97758 19.8333 10.4999 19.311 10.4999 18.6667V14ZM13.9999 12.8333C14.6443 12.8333 15.1666 13.3557 15.1666 14V18.6667C15.1666 19.311 14.6443 19.8333 13.9999 19.8333C13.3556 19.8333 12.8333 19.311 12.8333 18.6667V14C12.8333 13.3557 13.3556 12.8333 13.9999 12.8333ZM19.8333 14C19.8333 13.3557 19.3109 12.8333 18.6666 12.8333C18.0223 12.8333 17.4999 13.3557 17.4999 14V18.6667C17.4999 19.311 18.0223 19.8333 18.6666 19.8333C19.3109 19.8333 19.8333 19.311 19.8333 18.6667V14Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default SVGComponent;
