import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.scss';

const body = document.body;

const Portal = (props) => {
  const { show, children } = props;

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.portal}>
      <div className={styles.backdrop} />
      <div className={styles.content}>{children}</div>
    </div>,
    body
  );
};

export default Portal;
